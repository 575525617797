$red : #ED1D24;
$red-light : lighten($red, 20%);
$red-dark : darken($red, 20%);
$white : #ffffff;
$black : #111111;
$grey-1 : darken($white, 5%);
$grey-2: darken($grey-1, 5%);
$grey-3: darken($grey-2, 5%);
$grey-4: darken($grey-3, 5%);
$grey-5: darken($grey-4, 5%);

:root {
	--vh: 100vh;
	--bg-lighter : #{$white};
	--bg-light : #{$grey-1};
	--color-text : #{$black};
	--color-med : #{$grey-2};
	--color-primary-light : #{$red-light};
	--color-primary : #{$red};
	--color-primary-dark : #{$red-dark};
	--unit-md: 1.25rem;
	--unit-sm: calc(var(--unit-md) * 0.75);
	--unit-xs: calc(var(--unit-sm) * 0.75);
	--unit-lg: calc(var(--unit-md) * 1.25);
	--unit-xl: calc(var(--unit-lg) * 1.25);
}

::selection {
	background-color: $red;
	color: $white;
}


@import './fluid_font';

// UTILITY CLASSES AND BASE
.flex-col {
	display: flex;
	flex-flow: column;
}

.grow {
	flex-grow: 1;
}

.pad-x {
	padding-left: 1rem;
	padding-right: 1rem;
}

.pad-y {
	padding-left: 1rem;
	padding-right: 1rem;
}

.pad {
	padding: 1rem;
}

.hide {
	display: none !important;
}

.scroll-hidden {
	overflow: hidden;
}

.icon {
	--size: 24px;
	display: inline-block;
	color: inherit; // icons inherit the text color
	fill: currentColor;
	height: var(--size);
	width: var(--size);
	line-height: 1;
	flex-shrink: 0;
	max-width: initial;
}

.synthP {
	font-size: clamp(1.1rem, 0.8000rem + 1.3333vw, 1.5rem);

}

.max-width-md {
	max-width: 55rem;
	width: 100%;
	padding-right: var(--space-md);
	padding-left: var(--space-md);
}

.max-width-sm {
	max-width: 35rem;
	width: 100%;
}

.ft-primary {
	font-family: 'Barlow', sans-serif;
}

.ft-primary-cd {
	font-family: 'Barlow Condensed', sans-serif;
}

.ft-secondary {
	font-family: 'Overpass', sans-serif;
}

h1,
h2,
h3 {
	@extend .ft-primary-cd;
}

h4,
h5,
h6 {
	@extend .ft-primary-cd;
}

p {
	color: var(--color-text);
}

figure img,
img {
	vertical-align: middle;
	background-color: var(--bg-light);
	width: 100%;
	object-fit: cover;
	object-position: top;

}

figure img {
	height: 100%;
}

.shadow {

	box-shadow: 0 0 5px rgba(0, 0, 0, 0.075);
}

.border {
	border: 1px solid var(--color-med);
}

.border-dark {
	border-bottom: 1px solid var(--color-med);
}


// MAIN STRUCTURE
body,
html {
	padding: 0;
	margin: 0;
	height: 100%;
	max-height: calc(var(--vh, 1vh) * 100), 100vh;
	overflow: hidden;
	background-color: var(--bg-lighter);
	@extend .ft-secondary;
}

body.others {
	.headerLogo {
		opacity: 1;
		clip-path: inset(0px 0px 0px 0px);
	}
}

body.home {
	.headerLogo {
		opacity: 0;
		clip-path: inset(0px 0px 100% 0px);
	}
}


header {
	display: flex;
	justify-content: center;
	padding: var(--unit-xs) var(--unit-md);

	.homeLink {
		max-width: 55rem;
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	.headerLogo {
		background-color: $red;
		color: $white;
		padding: 0.5em;
		position: absolute;
		top: calc(-1 * var(--unit-sm));
		left: 0;
		font-family: "Barlow", sans-serif;
		font-size: 1.25em;
		font-weight: 900;
		line-height: 1.12;
		transition: 0.3s ease all 0.25s;
	}


	h1 {
		margin-bottom: 0;
		font-weight: 600;
		font-size: 1.5rem;
		@extend .ft-primary-cd;
		color: var(--color-text);
		text-align: center;
	}
}

#inner__body {
	position: relative;
}


footer {
	display: flex;
	flex-flow: row wrap;
	padding-top: 0 !important;
	justify-content: center;
	gap: 0.5em;
	font-size: small;

	a,
	.dot {
		color: var(--color-text);
	}
}


article.mentions {

	// padding-top: 1em;
	// padding-bottom: 2em;
	h1,
	h2 {
		margin-bottom: 0.1em;
	}

	h1 {
		font-weight: 500;
		padding-top: 1em;
	}

	h2 {
		color: var(--color-primary-dark);
	}

	p+h2 {
		padding-top: 0.15em;
	}

	*~P {
		padding-top: 0;
	}

	&>a,
	p>a {
		text-decoration: underline;
	}

	footer {
		margin-top: 1em,
	}
}

article {
	width: 100%;
	--plyr-color-main: var(--color);
	align-items: center;

	&>*.fullWidth {
		width: 100%;
	}

	&>*:not(.fullWidth) {
		@extend .max-width-md;
		padding: var(--unit-md);
	}


}

section {
	flex: 1;
	overflow: auto;
	position: relative;
	will-change: transform, opacity;

	&.home {

		figure.hero {
			max-height: 60vh;

		}

		.homeIntro {
			margin-top: var(--unit-md);
			margin-right: var(--unit-md);
			width: -moz-fit-content;
			width: fit-content;
			@extend .synthP;
		}

		ul.podcasts_wrapper {
			--image-size: 6rem;

			&>*+* {
				padding: var(--unit-md) 0;
				border-top: 1px solid var(--color-med);
			}

			.podcastEl {
				--indic-width: 4px;

				&:first-child {
					padding-bottom: var(--unit-md);
				}

				a {
					display: flex;
					gap: var(--unit-md);
					align-items: center;
					position: relative;

					&:hover:after,
					&:focus:after {
						content: "";
						width: var(--indic-width);
						background-color: var(--color-primary);
						position: absolute;
						right: 0;
						height: calc(100% - var(--unit-sm) *2);
						border-radius: var(--indic-width) 0 0 var(--indic-width);
					}

					figure {
						max-width: var(--image-size);
						min-width: var(--image-size);
						height: var(--image-size);
						border-radius: var(--unit-sm);
						overflow: hidden;
						@extend .shadow;

						img {
							height: 100%;
							object-fit: cover;

						}
					}

					.podcast--text {
						h2 {
							margin-bottom: 0.12em;
							font-weight: 700;
							color: var(--color);
							line-height: 0.8;
						}

						p {
							margin-top: 0.45em;
							font-weight: 600;
						}
					}
				}
			}
		}
	}

	&.podcast {
		--figure-size: 25rem;

		.podcast--header {
			position: relative;
			display: flex;
			align-items: center;
			flex-flow: column;
			// @extend .ct-width;
			width: calc(100% - var(--unit-md)*2);
			max-width: var(--figure-size);

			figure {
				max-width: var(--figure-size);
				max-height: calc(var(--figure-size));
				width: 100%;
				border-radius: var(--unit-sm);
				height: 100%;

				img.hero {
					width: 100%;
					height: 100%;
					border-radius: var(--unit-sm);
				}
			}

			h2 {
				margin: var(--unit-lg) 0 8px;
				color: var(--color);
				font-family: 'Barlow Condensed', sans-serif;
				font-weight: 700;
				text-align: center;
			}

			p {
				font-size: 18px;
				font-weight: 600;
				text-align: center;
				color: var(--color-text);
				margin-bottom: var(--space-lg);
				margin-right: var(--space-md);
				margin-left: var(--space-md);
			}
		}

		.podcast--details {
			width: 100%;
			text-align: center;
			padding-top: 0;
			@extend .max-width-sm;

			p {
				line-height: 1.3;
			}

			*+* {
				margin-top: 0.5em;
			}
		}
	}
}



#playlister {
	transition: max-height 0.35s ease-out;
	max-height: 0rem;
	width: 100%;
	max-width: 60rem;
	display: flex;
	flex-flow: column nowrap;
	margin: 0 auto;
	border-radius: var(--unit-sm) var(--unit-sm) 0 0;
	border-top: 1px solid var(--color-med);

	.currentTitle {
		margin: var(--unit-sm) var(--unit-sm) var(--unit-xs);
		font-weight: 600;
		color: var(--color-text);

		a {
			text-decoration: underline;
			text-decoration-color: var(--color);
			text-underline-offset: 0.1em;
			text-decoration-thickness: 0.15em;
		}
	}

	#nextpodcast {
		margin: 0 var(--unit-sm) var(--unit-xs);
		display: flex;
		flex-flow: column;
		color: var(--color-text);
		align-items: center;

		a {
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-start;
			text-decoration: underline;
			text-decoration-color: var(--color);
			text-decoration-thickness: 0.15em;
			text-underline-offset: 0.1em;
			color: inherit;
		}
	}
}


header,
#playlister {
	background-color: var(--bg-light);
	z-index: 4;

}




//PLYR THEMING

.plyr--audio {
	margin-bottom: var(--unit-xs);
	--plyr-color-main: var(--color);

	.plyr__controls {
		background-color: transparent;
		margin: 0 var(--unit-sm);
		padding: 0;
		gap: var(--unit-xs);
		color: var(--color-text);

		.plyr__progress {
			flex-grow: 1;
			margin: 0 var(--unit-xs);
			left: 0;
		}

		.plyr__custom__control {
			position: relative;

			.plyr__time {
				position: absolute;
				right: 0;
				top: calc(-1.5 * var(--unit-md));
			}
		}
	}
}



//PLAYPOD
#playpod {
	align-items: center;
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
	transition: max-height 0.35s ease-out;
	background: -webkit-linear-gradient(bottom, var(--bg-lighter), rgba(white, 0));

	.wrapper {
		cursor: pointer;
		@extend .shadow;

		font-weight: 600;
		padding: 0.75em 1.25rem;
		border-radius: 50em;
		background-color: var(--color);
		color: $white;
		gap: 0.5rem;
		display: flex;
		align-items: center;
		width: -moz-fit-content;
		width: fit-content;
		margin: 1rem 0;
	}

}


//TRANSITION

#transition__Wrapper {
	background-color: var(--bg-light);
	color: var(--color-primary);
	will-change: clip-path;
	z-index: 2;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: -ms-grid;
	display: grid;
	place-items: center;
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;

	div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: var(--color-primary);
		animation-timing-function: cubic-bezier(0, 1, 1, 0);

		&:nth-child(1) {
			left: 8px;
			animation: lds-ellipsis1 0.6s infinite;
		}

		&:nth-child(2) {
			left: 8px;
			animation: lds-ellipsis2 0.6s infinite;
		}

		&:nth-child(3) {
			left: 32px;
			animation: lds-ellipsis2 0.6s infinite;
		}

		&:nth-child(4) {
			left: 56px;
			animation: lds-ellipsis3 0.6s infinite;
		}
	}
}


@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(24px, 0);
	}
}



body.plyr--playing {
	#playlister {
		max-height: var(--player-h);
	}
}

body.next-proposed {
	#playlister {
		max-height: 10rem;
	}
}




// DARK THEME


@media (prefers-color-scheme: dark) {
	:root {
		--bg-lighter: #222;
		--bg-light: #333;
		--color-text : #{$white};
		--color-med: #666;
		--color-primary-light : #{$red-light};
		--color-primary : #{$red};
		--color-primary-dark : #{$red-dark};
	}

	#playpod {
		background: -webkit-linear-gradient(bottom, var(--bg-lighter), rgba(0, 0, 0, 0));

	}
}





@media all and (max-width:320px) {
	.time__control {
		display: none;
	}

	.plyr__time--current {
		top: calc(-2.25 * var(--unit-md)) !important;
	}

	.plyr__progress {
		margin-right: calc(-0.25 * var(--unit-sm)) !important;
	}
}




@media all and (max-width:640px) {
	header .homeLink {
		justify-content: flex-end;
	}

	body.home header .homeLink {
		justify-content: center !important;
	}

	header h1 {
		font-size: 1.35rem;
	}
}