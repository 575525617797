:root {
  --vh: 100vh;
  --bg-lighter: #ffffff;
  --bg-light: #f2f2f2;
  --color-text: #111111;
  --color-med: #e6e6e6;
  --color-primary-light: #f57b80;
  --color-primary: #ED1D24;
  --color-primary-dark: #980c11;
  --unit-md: 1.25rem;
  --unit-sm: calc(var(--unit-md) * 0.75);
  --unit-xs: calc(var(--unit-sm) * 0.75);
  --unit-lg: calc(var(--unit-md) * 1.25);
  --unit-xl: calc(var(--unit-lg) * 1.25);
}

::selection {
  background-color: #ED1D24;
  color: #ffffff;
}

h4,
.h4 {
  font-size: 1.333rem;
  line-height: calc(2px + 2ex + 2px);
  margin-bottom: 0.65em;
  font-size: clamp(
        1rem,
        4.333vw + 1rem,
        1.333rem
      );
}

h3,
.h3 {
  font-size: 1.776889rem;
  line-height: calc(2px + 2ex + 2px);
  margin-bottom: 0.65em;
  font-size: clamp(
        1.19051563rem,
        4.58637337vw + 1rem,
        1.776889rem
      );
}

h2,
.h2 {
  font-size: 2.368593037rem;
  line-height: calc(2px + 2ex + 2px);
  margin-bottom: 0.65em;
  font-size: clamp(
        1.5869573348rem,
        4.7816357022vw + 1rem,
        2.368593037rem
      );
}

h1,
.h1 {
  font-size: 3.1573345183rem;
  line-height: calc(2px + 2ex + 2px);
  margin-bottom: 0.65em;
  font-size: clamp(
        2.1154141273rem,
        5.041920391vw + 1rem,
        3.1573345183rem
      );
}

p,
li,
h1,
h2,
h3,
h4 {
  word-break: break-word;
  hyphens: auto;
  margin-top: 0;
}

.flex-col {
  display: flex;
  flex-flow: column;
}

.grow {
  flex-grow: 1;
}

.pad-x {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pad-y {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pad {
  padding: 1rem;
}

.hide {
  display: none !important;
}

.scroll-hidden {
  overflow: hidden;
}

.icon {
  --size: 24px;
  display: inline-block;
  color: inherit;
  fill: currentColor;
  height: var(--size);
  width: var(--size);
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.synthP, section.home .homeIntro {
  font-size: clamp(1.1rem, 0.8rem + 1.3333vw, 1.5rem);
}

.max-width-md, article > *:not(.fullWidth) {
  max-width: 55rem;
  width: 100%;
  padding-right: var(--space-md);
  padding-left: var(--space-md);
}

.max-width-sm, section.podcast .podcast--details {
  max-width: 35rem;
  width: 100%;
}

.ft-primary {
  font-family: "Barlow", sans-serif;
}

.ft-primary-cd, header h1, h4,
h5,
h6, h1,
h2,
h3 {
  font-family: "Barlow Condensed", sans-serif;
}

.ft-secondary, body,
html {
  font-family: "Overpass", sans-serif;
}

p {
  color: var(--color-text);
}

figure img,
img {
  vertical-align: middle;
  background-color: var(--bg-light);
  width: 100%;
  object-fit: cover;
  object-position: top;
}

figure img {
  height: 100%;
}

.shadow, #playpod .wrapper, section.home ul.podcasts_wrapper .podcastEl a figure {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.075);
}

.border {
  border: 1px solid var(--color-med);
}

.border-dark {
  border-bottom: 1px solid var(--color-med);
}

body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
  max-height: calc(var(--vh, 1vh) * 100), 100vh;
  overflow: hidden;
  background-color: var(--bg-lighter);
}

body.others .headerLogo {
  opacity: 1;
  clip-path: inset(0px 0px 0px 0px);
}

body.home .headerLogo {
  opacity: 0;
  clip-path: inset(0px 0px 100% 0px);
}

header {
  display: flex;
  justify-content: center;
  padding: var(--unit-xs) var(--unit-md);
}
header .homeLink {
  max-width: 55rem;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
}
header .headerLogo {
  background-color: #ED1D24;
  color: #ffffff;
  padding: 0.5em;
  position: absolute;
  top: calc(-1 * var(--unit-sm));
  left: 0;
  font-family: "Barlow", sans-serif;
  font-size: 1.25em;
  font-weight: 900;
  line-height: 1.12;
  transition: 0.3s ease all 0.25s;
}
header h1 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--color-text);
  text-align: center;
}

#inner__body {
  position: relative;
}

footer {
  display: flex;
  flex-flow: row wrap;
  padding-top: 0 !important;
  justify-content: center;
  gap: 0.5em;
  font-size: small;
}
footer a,
footer .dot {
  color: var(--color-text);
}

article.mentions h1,
article.mentions h2 {
  margin-bottom: 0.1em;
}
article.mentions h1 {
  font-weight: 500;
  padding-top: 1em;
}
article.mentions h2 {
  color: var(--color-primary-dark);
}
article.mentions p + h2 {
  padding-top: 0.15em;
}
article.mentions * ~ P {
  padding-top: 0;
}
article.mentions > a,
article.mentions p > a {
  text-decoration: underline;
}
article.mentions footer {
  margin-top: 1em;
}

article {
  width: 100%;
  --plyr-color-main: var(--color);
  align-items: center;
}
article > *.fullWidth {
  width: 100%;
}
article > *:not(.fullWidth) {
  padding: var(--unit-md);
}

section {
  flex: 1;
  overflow: auto;
  position: relative;
  will-change: transform, opacity;
}
section.home figure.hero {
  max-height: 60vh;
}
section.home .homeIntro {
  margin-top: var(--unit-md);
  margin-right: var(--unit-md);
  width: -moz-fit-content;
  width: fit-content;
}
section.home ul.podcasts_wrapper {
  --image-size: 6rem;
}
section.home ul.podcasts_wrapper > * + * {
  padding: var(--unit-md) 0;
  border-top: 1px solid var(--color-med);
}
section.home ul.podcasts_wrapper .podcastEl {
  --indic-width: 4px;
}
section.home ul.podcasts_wrapper .podcastEl:first-child {
  padding-bottom: var(--unit-md);
}
section.home ul.podcasts_wrapper .podcastEl a {
  display: flex;
  gap: var(--unit-md);
  align-items: center;
  position: relative;
}
section.home ul.podcasts_wrapper .podcastEl a:hover:after, section.home ul.podcasts_wrapper .podcastEl a:focus:after {
  content: "";
  width: var(--indic-width);
  background-color: var(--color-primary);
  position: absolute;
  right: 0;
  height: calc(100% - var(--unit-sm) * 2);
  border-radius: var(--indic-width) 0 0 var(--indic-width);
}
section.home ul.podcasts_wrapper .podcastEl a figure {
  max-width: var(--image-size);
  min-width: var(--image-size);
  height: var(--image-size);
  border-radius: var(--unit-sm);
  overflow: hidden;
}
section.home ul.podcasts_wrapper .podcastEl a figure img {
  height: 100%;
  object-fit: cover;
}
section.home ul.podcasts_wrapper .podcastEl a .podcast--text h2 {
  margin-bottom: 0.12em;
  font-weight: 700;
  color: var(--color);
  line-height: 0.8;
}
section.home ul.podcasts_wrapper .podcastEl a .podcast--text p {
  margin-top: 0.45em;
  font-weight: 600;
}
section.podcast {
  --figure-size: 25rem;
}
section.podcast .podcast--header {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column;
  width: calc(100% - var(--unit-md) * 2);
  max-width: var(--figure-size);
}
section.podcast .podcast--header figure {
  max-width: var(--figure-size);
  max-height: calc(var(--figure-size));
  width: 100%;
  border-radius: var(--unit-sm);
  height: 100%;
}
section.podcast .podcast--header figure img.hero {
  width: 100%;
  height: 100%;
  border-radius: var(--unit-sm);
}
section.podcast .podcast--header h2 {
  margin: var(--unit-lg) 0 8px;
  color: var(--color);
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  text-align: center;
}
section.podcast .podcast--header p {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: var(--color-text);
  margin-bottom: var(--space-lg);
  margin-right: var(--space-md);
  margin-left: var(--space-md);
}
section.podcast .podcast--details {
  width: 100%;
  text-align: center;
  padding-top: 0;
}
section.podcast .podcast--details p {
  line-height: 1.3;
}
section.podcast .podcast--details * + * {
  margin-top: 0.5em;
}

#playlister {
  transition: max-height 0.35s ease-out;
  max-height: 0rem;
  width: 100%;
  max-width: 60rem;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  border-radius: var(--unit-sm) var(--unit-sm) 0 0;
  border-top: 1px solid var(--color-med);
}
#playlister .currentTitle {
  margin: var(--unit-sm) var(--unit-sm) var(--unit-xs);
  font-weight: 600;
  color: var(--color-text);
}
#playlister .currentTitle a {
  text-decoration: underline;
  text-decoration-color: var(--color);
  text-underline-offset: 0.1em;
  text-decoration-thickness: 0.15em;
}
#playlister #nextpodcast {
  margin: 0 var(--unit-sm) var(--unit-xs);
  display: flex;
  flex-flow: column;
  color: var(--color-text);
  align-items: center;
}
#playlister #nextpodcast a {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  text-decoration: underline;
  text-decoration-color: var(--color);
  text-decoration-thickness: 0.15em;
  text-underline-offset: 0.1em;
  color: inherit;
}

header,
#playlister {
  background-color: var(--bg-light);
  z-index: 4;
}

.plyr--audio {
  margin-bottom: var(--unit-xs);
  --plyr-color-main: var(--color);
}
.plyr--audio .plyr__controls {
  background-color: transparent;
  margin: 0 var(--unit-sm);
  padding: 0;
  gap: var(--unit-xs);
  color: var(--color-text);
}
.plyr--audio .plyr__controls .plyr__progress {
  flex-grow: 1;
  margin: 0 var(--unit-xs);
  left: 0;
}
.plyr--audio .plyr__controls .plyr__custom__control {
  position: relative;
}
.plyr--audio .plyr__controls .plyr__custom__control .plyr__time {
  position: absolute;
  right: 0;
  top: calc(-1.5 * var(--unit-md));
}

#playpod {
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  transition: max-height 0.35s ease-out;
  background: -webkit-linear-gradient(bottom, var(--bg-lighter), rgba(255, 255, 255, 0));
}
#playpod .wrapper {
  cursor: pointer;
  font-weight: 600;
  padding: 0.75em 1.25rem;
  border-radius: 50em;
  background-color: var(--color);
  color: #ffffff;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem 0;
}

#transition__Wrapper {
  background-color: var(--bg-light);
  color: var(--color-primary);
  will-change: clip-path;
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--color-primary);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
body.plyr--playing #playlister {
  max-height: var(--player-h);
}

body.next-proposed #playlister {
  max-height: 10rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-lighter: #222;
    --bg-light: #333;
    --color-text: #ffffff;
    --color-med: #666;
    --color-primary-light: #f57b80;
    --color-primary: #ED1D24;
    --color-primary-dark: #980c11;
  }

  #playpod {
    background: -webkit-linear-gradient(bottom, var(--bg-lighter), rgba(0, 0, 0, 0));
  }
}
@media all and (max-width: 320px) {
  .time__control {
    display: none;
  }

  .plyr__time--current {
    top: calc(-2.25 * var(--unit-md)) !important;
  }

  .plyr__progress {
    margin-right: calc(-0.25 * var(--unit-sm)) !important;
  }
}
@media all and (max-width: 640px) {
  header .homeLink {
    justify-content: flex-end;
  }

  body.home header .homeLink {
    justify-content: center !important;
  }

  header h1 {
    font-size: 1.35rem;
  }
}
/*# sourceMappingURL=index.11cfbea8.css.map */
